import * as Sentry from '@sentry/browser';

const environment =
  import.meta.env.MODE === 'development'
    ? 'development'
    : window.location.hostname.startsWith('staging')
      ? 'staging'
      : 'production';

Sentry.init({
  dsn: 'https://fc28ec579946496cbe547c80183e59ac@o160861.ingest.sentry.io/1301543',
  environment,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    'fb_xd_fragment', // Facebook borked
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    '_avast_submit',
  ],
  blacklistUrls: [
    /www\.google-analytics\.com/i, // Google Analytics blocked
    /graph\.facebook\.com/i, // Facebook flakiness
    /connect\.facebook\.net\/en_US\/all\.js/i, // Facebook blocked
    /extensions\//i, // Chrome extensions
    /^chrome:\/\//i, // Chrome extensions
    /^resource:\/\//i, // Firefox extensions
  ],

  tracesSampleRate: 1.0,
});
